import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';

class sideNavigation extends AEM.Component {
    init() {
        let leftSidebarButton = this.element.querySelector('#toggle-left-sidebar');
        let rightSidebarButton = this.element.querySelector('#toggle-right-sidebar');
        let rightAnchorBar = this.element.querySelector('.side-nav-right .anchorBar');

        if (rightAnchorBar && rightAnchorBar.children.length === 0) {
            rightSidebarButton.style.display = 'none';
        }

        leftSidebarButton?.addEventListener('click', () => {
            const leftSidebar = this.element.querySelector('.side-nav-left');
            leftSidebar?.classList.toggle('hidden');
            leftSidebarButton.classList.toggle('show');
        });

        rightSidebarButton?.addEventListener('click', () => {
            const rightSidebar = this.element.querySelector('.side-nav-right');
            rightSidebar?.classList.toggle('hidden');
            rightSidebarButton.classList.toggle('show');
        });
    }
}

export { sideNavigation };
